<template>
    <div class="box f_color1 font2">
      <van-nav-bar :title="this.groupInfo ? (this.groupInfo.type === 0 ? '二级收益流水' : '二级充值流水(撩币)') : ''" left-text="" left-arrow
        @click-left="onClickLeft" @click-right="onClickRight" />
      <div v-if="!!groupInfo">
        <van-search class="search" v-model="search" @search="onSearch" shape="round" show-action placeholder="输入组长ID">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
        <div class="search-type">
          <div class="id-type">
            <van-radio-group v-model="checked" direction="horizontal">
              <van-radio name="0" icon-size="14px" class="font1">组长ID</van-radio>
              <van-radio name="1" icon-size="14px" class="font1">组ID</van-radio>
            </van-radio-group>
          </div>
          <van-dropdown-menu v-if="this.groupInfo && this.groupInfo.type === 0" class="income-type"
            active-color="#1989fa">
            <van-dropdown-item v-model="incomeType" :options="incomeTypes" @change="onIncomeTypeChanged" />
          </van-dropdown-menu>
        </div>
        <div class="search-time">
          <div class="time ltime" @click="onTimeBeginTapped">&nbsp;&nbsp;{{ timeBeginDisplay }}&nbsp;&nbsp;</div>
          <span>&nbsp;至&nbsp;</span>
          <div class="time" @click="onTimeEndTapped">&nbsp;&nbsp;{{ timeEndDisplay }}&nbsp;&nbsp;</div>
        </div>
        <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
        <van-list class="list font2" v-model:loading="loading" :immediate-check="false" :finished="finished"
          finished-text="没有更多了" @load="onLoad">
          <div class="listItem" v-for="(li, i) in list" :key="i">
            <div class="body">
              <div class="left">
                <div class="name">{{ li.userInfo.nickName }} </div>
                <div class="uid">ID: {{ li.userId }}</div>
                
                <div class="uid">组ID: {{ li.tid }}</div>
              </div>
              <div class="num">{{ li.num }} </div>
            </div>
            <div class="foot">
              <div class="time">{{ getDisplayTime(li.time) }}</div>
              <div class="desc">{{ li.transactionName }}</div>
            </div>
          </div>
        </van-list>
        <!--            </van-pull-refresh>-->
        <van-popup v-model:show="showDatePicker" position="bottom" round>
          <van-datetime-picker v-model="datePicker" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate"
            @confirm="onDatePickerConfirm" @cancel="onDatePickerCancel" />
        </van-popup>
      </div>
      <div v-if="!!groupInfo" class="bottom">
        <div class="part">
          <div class="value">{{ totalIncome }}</div>
          <div class="key">{{ this.groupInfo ? (this.groupInfo.type === 0 ? '总收益' : '总充值') : ''}}</div>
        </div>
        <div class="part">
          <div class="value">&nbsp;</div>
          <div class="key">&nbsp;</div>
        </div>
        <div class="part">
          <div class="value">{{ parentComission }}</div>
          <div class="key">二级组长分成</div>
        </div>
        <!-- <div class="part">
          <div class="value">{{ leaderComission }}</div>
          <div class="key">二级组长分成</div>
        </div> -->
      </div>
      <Mask v-if="marke"></Mask>
    </div>
  </template>
  <script>
  // import { wxqq } from '../utils/utils'
  import Mask from '../components/Mask'
  import routerUtil from '../utils/routerUtil'
  import { Toast } from "vant"
  import moment from 'moment'
  
  export default {
    name: 'twolevel',
    data() {
      return {
        search: '',
        checked: '0',
        incomeType: 0,
        incomeTypes: [
          { text: '全部类型', value: 0 },
          { text: '私聊送礼', value: 22 },
          { text: '心动送礼', value: 63 },
          { text: '普通聊天', value: 52 },
          { text: '语音', value: 37 },
          { text: '视频', value: 55 },
        ],
        timeBegin: 0,
        timeEnd: 0,
        showDatePicker: false,
        datePickerMode: 0,
        datePicker: null,
        minDate: null,
        maxDate: null,
        groupInfo: null,
        searchUid: null,
        searchType: 0,
        loading: false,
        finished: false,
        refreshing: false,
        list: [],
        totalIncome: 0,
        parentComission: 0,
        leaderComission: 0,
        marke: false,
      }
    },
    components: {
      Mask,
    },
    computed: {
      timeBeginDisplay() {
        const date = new Date(this.timeBegin);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${date.getFullYear()}/${month > 9 ? month : '0' + month}/${day > 9 ? day : '0' + day}`;
      },
      timeEndDisplay() {
        const date = new Date(this.timeEnd);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${date.getFullYear()}/${month > 9 ? month : '0' + month}/${day > 9 ? day : '0' + day}`;
      }
    },
    mounted() {
      if (this.$route.params.userId) {
        this.search = this.$route.params.userId
        this.searchUid = parseInt(this.search);
      }
      this.reqInfo()
      // this.marke = wxqq()
    },
    methods: {
      reqInfo() {
        this.$request.postJson('/api/user/pay_group/info', {})
          .then((res) => {
            if (res.data.code == 0) {
              this.groupInfo = res.data.data;
              if (!this.groupInfo) {
                Toast('暂无推广组信息');
                routerUtil.back()
                return;
              }
              switch (this.groupInfo.period) {
                case 1:
                  this.timeBegin = moment().startOf('day').valueOf();
                  this.timeEnd = moment().endOf('day').valueOf();
                  break;
                case 2:
                  moment
                  this.timeBegin = moment().startOf('isoWeek').valueOf();
                  this.timeEnd = moment().endOf('isoWeek').valueOf();
                  break;
                case 3:
                  this.timeBegin = moment().startOf('month').valueOf();
                  this.timeEnd = moment().endOf('day').valueOf();
                  break;
              }
              this.getList()
              this.getStatistics()
            }
          })
      },
      getList(type) {
        let lastId = ''
        if (type && this.list.length > 0) {
          lastId = this.list[this.list.length - 1].id
        }
        this.$request.postJson('/api/user/pay_group/twoincome_logs', {
          groupId: this.groupInfo.id,
          type: this.searchType,
          userId: this.searchUid == null ? 0 : this.searchUid,
          transType: this.incomeType,
          startTime: this.timeBegin,
          endTime: this.timeEnd,
          lastId: lastId,
          pageSize: 15
        }).then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.logs.length == 0) {
              this.loading = false;
              this.refreshing = false
              this.finished = true;
              return
            }
            const uids = [];
            for (let log of res.data.data.logs) {
              uids.push(log.userId);
            }
            this.$request.post('/api/user/info/twouserInfo', { userIds: JSON.stringify(uids) })
              .then((res1) => {
                this.loading = false;
                this.refreshing = false
                if (res1.data.code == 0) {
                  const userMap = new Map();
                  for (let user of res1.data.data.users) {
                    if (user.code == 0) {
                      userMap.set(user.userId, user);
                    } else {
                      Toast("请求用户基本信息失败")
                      return;
                    }
                  }
                  for (let log of res.data.data.logs) {
                    log.userInfo = userMap.get(log.userId);
                  }
                  this.list.push(...res.data.data.logs)
                  this.finished = res.data.data.logs.length < 15
                }
              })
          }
        })
      },
      getStatistics() {
        this.$request.postJson('/api/user/pay_group/twoincome_stats', {
          groupId: this.groupInfo.id,
          type: this.searchType,
          userId: this.searchUid == null ? 0 : this.searchUid,
          transType: this.incomeType,
          startTime: this.timeBegin,
          endTime: this.timeEnd,
        }).then((res) => {
          if (res.data.code == 0) {
            this.totalIncome = res.data.data.totalIncome;
            this.parentComission = res.data.data.parentShare;
            this.leaderComission = res.data.data.groupOwnerShare;
          }
        })
      },
      onClickLeft() {
        routerUtil.back()
      },
      onClickRight() {
      },
      onSearch() {
        if (this.search == "") {
          this.searchUid = null
        } else {
          if (isNaN(Number(this.search, 10))) {
            Toast("请输入用户ID")
            return
          }
          const uid = parseInt(this.search);
          this.searchUid = uid;
        }
        this.searchType = parseInt(this.checked)
        this.list = [];
        this.onRefresh()
        this.getStatistics()
      },
      onIncomeTypeChanged() {
        this.list = [];
        this.onRefresh()
        this.getStatistics()
      },
      onTimeBeginTapped() {
        this.datePickerMode = 1;
        this.datePicker = new Date(this.timeBegin);
        this.minDate = new Date(2020, 0, 1);
        this.maxDate = new Date();
        this.showDatePicker = true;
      },
      onTimeEndTapped() {
        this.datePickerMode = 2;
        this.datePicker = new Date(this.timeEnd);
        this.minDate = new Date(this.timeBegin);
        this.maxDate = new Date();
        this.showDatePicker = true;
      },
      onDatePickerConfirm(value) {
        switch (this.datePickerMode) {
          case 1:
            this.timeBegin = moment(value).startOf('day').valueOf();
            if (this.timeEnd < this.timeBegin) {
              this.timeEnd = moment(value).endOf('day').valueOf();
            }
            break;
          case 2:
            this.timeEnd = moment(value).endOf('day').valueOf();
            break;
        }
        this.datePickerMode = 0;
        this.showDatePicker = false;
        this.list = [];
        this.onRefresh()
        this.getStatistics()
      },
      onDatePickerCancel() {
        this.datePickerMode = 0;
        this.showDatePicker = false;
      },
      onLoad() {
        this.getList(true)
      },
      onRefresh() {
        // 清空列表数据
        this.finished = false;
  
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.getList()
      },
      getDisplayTime(time) {
        const date = new Date(time);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hour = date.getHours();
        const min = date.getMinutes();
        const sec = date.getSeconds();
        return `${date.getFullYear()}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day} ${hour > 9 ? hour : '0' + hour}:${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`;
      }
    },
  }
  </script>
  <style scoped lang="less">
  .box {
    width: 100%;
    height: 100%;
  
    .search {
      padding-bottom: 0;
    }
  
    .search-type {
      height: 40px;
  
      .id-type {
        display: inline-block;
        padding: 10px 12px;
      }
  
      .income-type {
        float: right;
        width: 100px;
        margin-right: 10px;
  
        /deep/ .van-dropdown-menu__bar {
          box-shadow: none;
          height: 40px;
        }
      }
    }
  
    .search-time {
      width: 100%;
      padding-bottom: 10px;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
  
      .ltime {
        margin-left: 12px;
      }
  
      .time {
        display: inline-block;
        background-color: #00000010;
        border-radius: 4px;
      }
    }
  }
  
  .list {
    position: absolute;
    width: 100%;
    padding-bottom: 50px;
    background-color: #f6f6f6;
  
    .listItem {
      width: 100%;
      height: 100px;
      margin-top: 10px;
      background-color: #fff;
  
      .body {
        width: 100%;
        height: 80px;
  
        .left {
          display: inline-block;
          padding-left: 20px;
  
          .name {
            padding-top: 15px;
            font-size: 16px;
            height: 16px;
            line-height: 16px;
            color: #000;
          }
  
          .uid {
            padding-top: 10px;
            font-size: 14px;
            height: 14px;
            line-height: 14px;
            color: #787878;
          }
        }
  
        .num {
          float: right;
          padding-right: 20px;
          font-size: 14px;
          height: 70px;
          line-height: 70px;
          font-weight: bold;
          color: #000;
        }
      }
  
      .foot {
        width: 100%;
        height: 30px;
  
        .time {
          display: inline-block;
          padding-left: 20px;
          font-size: 14px;
          color: #787878;
        }
  
        .desc {
          float: right;
          padding-right: 20px;
          font-size: 14px;
          color: #f29a38;
        }
      }
    }
  }
  
  .bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #f2f2f2;
    border-radius: 20px 20px 0 0;
  
    .part {
      display: inline-block;
      width: calc(100% / 3);
      height: 50px;
  
      .key {
        margin-top: 6px;
        font-size: 12px;
        height: 12px;
        line-height: 12px;
        color: #787878;
        text-align: center;
      }
  
      .value {
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
        height: 14px;
        line-height: 14px;
        color: #000;
        text-align: center;
      }
    }
  }
  
  .van-pull-refresh {
    height: 100% !important;
    overflow: auto !important;
  }
  
  .van-list {
    height: auto !important;
  }
  </style>
  